@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'ngx-toastr/toastr-bs5-alert';
@import 'bootstrap/scss/bootstrap';

@import "@angular/material/prebuilt-themes/indigo-pink.css";

::ng-deep {

  html,
  body {
    overflow-x: hidden !important;
  }

  body {
    position: relative !important;
  }
}

button:disabled {
  background: #dddddd;
}

button:hover:disabled {
  background: #dddddd;
}

input.ng-invalid.ng-touched {
  color: #495057;
  background-color: #fff;
  border-color: #ff3b00;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25);
}

.erro {
  font-weight: bold;
  color: red;
  margin-top: 10px;
  font-size: 12px;
  margin-left: 5px;
}

textarea {
  resize: none;
}

/*
Theme Name: FooYes
Theme URI: http://www.ansonika.com/fooyes/
Author: Ansonika
Author URI: http://themeforest.net/user/Ansonika/

[Table of contents]

0. ICONS

1. SITE STRUCTURE and TYPOGRAPHY
- 1.1 Typography
- 1.2 Structure
- 1.3 Main Menu
- 1.4 Heros/Above the fold
- 1.5 Drop down cart
- 1.6 Drop down user logged

2. CONTENT
- 2.1 Home
- 2.2 Carousel
- 2.3 Strip item

3. COMMON
- 3.1 Misc
- 3.2 spacing
- 3.3 Buttons
- 3.4 Footer
- 3.5 Misc 2 + Forms
- 3.6 Magnific Popup CSS
- 3.7 Sing In Modal
- 3.8 Modal Dialog
- 3.9 Cookie bar

/*============================================================================================*/
/* 0. ICONS */
/*============================================================================================*/
@font-face {
  font-family: Glyphter;
  src: url(assets/icon_fonts/font/Glyphter.eot);
  src: url(assets/icon_fonts/font/Glyphter.eot?#iefix) format('embedded-opentype'), url(assets/icon_fonts/font/Glyphter.woff) format('woff'), url(assets/icon_fonts/font/Glyphter.ttf) format('truetype'), url(assets/icon_fonts/font/Glyphter.svg#Glyphter) format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

[class*=icon-]:before {
  display: inline-block;
  font-family: Glyphter;
  font-style: normal
}

.icon-icon_cart:before {
  content: '\0041'
}

.icon-icon_login:before {
  content: '\0042'
}

.icon-icon_heart:before {
  content: '\0043'
}

/* Generated by Glyphter (http://www.glyphter.com)*/
@font-face {
  font-family: 'food';
  src: url('assets/icon_fonts/font/food.eot');
  src: url('assets/icon_fonts/font/food.eot?#iefix') format('embedded-opentype'),
    url('assets/icon_fonts/font/food.woff') format('woff'),
    url('assets/icon_fonts/font/food.ttf') format('truetype'),
    url('assets/icon_fonts/font/food.svg#food') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class*='icon-']:before {
  display: inline-block;
  font-family: 'food';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.icon-food_icon_cake_2:before {
  content: '\0042';
}

.icon-food_icon_fish:before {
  content: '\0044';
}

.icon-food_icon_chicken:before {
  content: '\0045';
}

.icon-food_icon_bread_2:before {
  content: '\0046';
}

.icon-food_icon_coffee:before {
  content: '\0048';
}

.icon-food_icon_dish:before {
  content: '\0049';
}

.icon-food_icon_cloche:before {
  content: '\004a';
}

.icon-food_icon_glass:before {
  content: '\004b';
}

.icon-food_icon_fish_2:before {
  content: '\004c';
}

.icon-food_icon_hair:before {
  content: '\004e';
}

.icon-food_icon_cake_3:before {
  content: '\0051';
}

.icon-food_icon_icecream_2:before {
  content: '\0052';
}

.icon-food_icon_burgher:before {
  content: '\0054';
}

.icon-food_icon_beer:before {
  content: '\0055';
}

.icon-food_icon_burrito:before {
  content: '\0056';
}

.icon-clock_2:before {
  content: '\0057';
}

.icon-food_icon_pizza:before {
  content: '\0058';
}

.icon-user_2:before {
  content: '\0059';
  font-weight: bold;
}

.icon-food_icon_chinese:before {
  content: '\005a';
}

.icon-food_icon_vegetarian:before {
  content: '\0064';
}

.icon-food_icon_chili:before {
  content: '\0067';
}

.icon-food_icon_sushi:before {
  content: '\0068';
}

.icon-food_icon_delivery:before {
  content: '\0074';
}

.icon-food_icon_shop:before {
  content: '\0075';
}

.icon-food_icon_highlight:before {
  content: '\0076';
}

/*============================================================================================*/
/* ElegantIcons icons */
/*============================================================================================*/

@font-face {
  font-family: 'ElegantIcons';
  src: url('assets/icon_fonts/font/ElegantIcons.eot');
  src: url('assets/icon_fonts/font/ElegantIcons.eot?#iefix') format('embedded-opentype'),
    url('assets/icon_fonts/font/ElegantIcons.woff') format('woff'),
    url('assets/icon_fonts/font/ElegantIcons.ttf') format('truetype'),
    url('assets/icon_fonts/font/ElegantIcons.svg#ElegantIcons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[data-icon]:before {
  font-family: 'ElegantIcons';
  content: attr(data-icon);
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.arrow_up,
.arrow_down,
.arrow_left,
.arrow_right,
.arrow_left-up,
.arrow_right-up,
.arrow_right-down,
.arrow_left-down,
.arrow-up-down,
.arrow_up-down_alt,
.arrow_left-right_alt,
.arrow_left-right,
.arrow_expand_alt2,
.arrow_expand_alt,
.arrow_condense,
.arrow_expand,
.arrow_move,
.arrow_carrot-up,
.arrow_carrot-down,
.arrow_carrot-left,
.arrow_carrot-right,
.arrow_carrot-2up,
.arrow_carrot-2down,
.arrow_carrot-2left,
.arrow_carrot-2right,
.arrow_carrot-up_alt2,
.arrow_carrot-down_alt2,
.arrow_carrot-left_alt2,
.arrow_carrot-right_alt2,
.arrow_carrot-2up_alt2,
.arrow_carrot-2down_alt2,
.arrow_carrot-2left_alt2,
.arrow_carrot-2right_alt2,
.arrow_triangle-up,
.arrow_triangle-down,
.arrow_triangle-left,
.arrow_triangle-right,
.arrow_triangle-up_alt2,
.arrow_triangle-down_alt2,
.arrow_triangle-left_alt2,
.arrow_triangle-right_alt2,
.arrow_back,
.icon_minus-06,
.icon_plus,
.icon_close,
.icon_check,
.icon_minus_alt2,
.icon_plus_alt2,
.icon_close_alt2,
.icon_check_alt2,
.icon_zoom-out_alt,
.icon_zoom-in_alt,
.icon_search,
.icon_box-empty,
.icon_box-selected,
.icon_minus-box,
.icon_plus-box,
.icon_box-checked,
.icon_circle-empty,
.icon_circle-slelected,
.icon_stop_alt2,
.icon_stop,
.icon_pause_alt2,
.icon_pause,
.icon_menu,
.icon_menu-square_alt2,
.icon_menu-circle_alt2,
.icon_ul,
.icon_ol,
.icon_adjust-horiz,
.icon_adjust-vert,
.icon_document_alt,
.icon_documents_alt,
.icon_pencil,
.icon_pencil-edit_alt,
.icon_pencil-edit,
.icon_folder-alt,
.icon_folder-open_alt,
.icon_folder-add_alt,
.icon_info_alt,
.icon_error-oct_alt,
.icon_error-circle_alt,
.icon_error-triangle_alt,
.icon_question_alt2,
.icon_question,
.icon_comment_alt,
.icon_chat_alt,
.icon_vol-mute_alt,
.icon_volume-low_alt,
.icon_volume-high_alt,
.icon_quotations,
.icon_quotations_alt2,
.icon_clock_alt,
.icon_lock_alt,
.icon_lock-open_alt,
.icon_key_alt,
.icon_cloud_alt,
.icon_cloud-upload_alt,
.icon_cloud-download_alt,
.icon_image,
.icon_images,
.icon_lightbulb_alt,
.icon_gift_alt,
.icon_house_alt,
.icon_genius,
.icon_mobile,
.icon_tablet,
.icon_laptop,
.icon_desktop,
.icon_camera_alt,
.icon_mail_alt,
.icon_cone_alt,
.icon_ribbon_alt,
.icon_bag_alt,
.icon_creditcard,
.icon_cart_alt,
.icon_paperclip,
.icon_tag_alt,
.icon_tags_alt,
.icon_trash_alt,
.icon_cursor_alt,
.icon_mic_alt,
.icon_compass_alt,
.icon_pin_alt,
.icon_pushpin_alt,
.icon_map_alt,
.icon_drawer_alt,
.icon_toolbox_alt,
.icon_book_alt,
.icon_calendar,
.icon_film,
.icon_table,
.icon_contacts_alt,
.icon_headphones,
.icon_lifesaver,
.icon_piechart,
.icon_refresh,
.icon_link_alt,
.icon_link,
.icon_loading,
.icon_blocked,
.icon_archive_alt,
.icon_heart_alt,
.icon_star_alt,
.icon_star-half_alt,
.icon_star,
.icon_star-half,
.icon_tools,
.icon_tool,
.icon_cog,
.icon_cogs,
.arrow_up_alt,
.arrow_down_alt,
.arrow_left_alt,
.arrow_right_alt,
.arrow_left-up_alt,
.arrow_right-up_alt,
.arrow_right-down_alt,
.arrow_left-down_alt,
.arrow_condense_alt,
.arrow_expand_alt3,
.arrow_carrot_up_alt,
.arrow_carrot-down_alt,
.arrow_carrot-left_alt,
.arrow_carrot-right_alt,
.arrow_carrot-2up_alt,
.arrow_carrot-2dwnn_alt,
.arrow_carrot-2left_alt,
.arrow_carrot-2right_alt,
.arrow_triangle-up_alt,
.arrow_triangle-down_alt,
.arrow_triangle-left_alt,
.arrow_triangle-right_alt,
.icon_minus_alt,
.icon_plus_alt,
.icon_close_alt,
.icon_check_alt,
.icon_zoom-out,
.icon_zoom-in,
.icon_stop_alt,
.icon_menu-square_alt,
.icon_menu-circle_alt,
.icon_document,
.icon_documents,
.icon_pencil_alt,
.icon_folder,
.icon_folder-open,
.icon_folder-add,
.icon_folder_upload,
.icon_folder_download,
.icon_info,
.icon_error-circle,
.icon_error-oct,
.icon_error-triangle,
.icon_question_alt,
.icon_comment,
.icon_chat,
.icon_vol-mute,
.icon_volume-low,
.icon_volume-high,
.icon_quotations_alt,
.icon_clock,
.icon_lock,
.icon_lock-open,
.icon_key,
.icon_cloud,
.icon_cloud-upload,
.icon_cloud-download,
.icon_lightbulb,
.icon_gift,
.icon_house,
.icon_camera,
.icon_mail,
.icon_cone,
.icon_ribbon,
.icon_bag,
.icon_cart,
.icon_tag,
.icon_tags,
.icon_trash,
.icon_cursor,
.icon_mic,
.icon_compass,
.icon_pin,
.icon_pushpin,
.icon_map,
.icon_drawer,
.icon_toolbox,
.icon_book,
.icon_contacts,
.icon_archive,
.icon_heart,
.icon_profile,
.icon_group,
.icon_grid-2x2,
.icon_grid-3x3,
.icon_music,
.icon_pause_alt,
.icon_phone,
.icon_upload,
.icon_download,
.social_facebook,
.social_twitter,
.social_pinterest,
.social_googleplus,
.social_tumblr,
.social_tumbleupon,
.social_wordpress,
.social_instagram,
.social_dribbble,
.social_vimeo,
.social_linkedin,
.social_rss,
.social_deviantart,
.social_share,
.social_myspace,
.social_skype,
.social_youtube,
.social_picassa,
.social_googledrive,
.social_flickr,
.social_blogger,
.social_spotify,
.social_delicious,
.social_facebook_circle,
.social_twitter_circle,
.social_pinterest_circle,
.social_googleplus_circle,
.social_tumblr_circle,
.social_stumbleupon_circle,
.social_wordpress_circle,
.social_instagram_circle,
.social_dribbble_circle,
.social_vimeo_circle,
.social_linkedin_circle,
.social_rss_circle,
.social_deviantart_circle,
.social_share_circle,
.social_myspace_circle,
.social_skype_circle,
.social_youtube_circle,
.social_picassa_circle,
.social_googledrive_alt2,
.social_flickr_circle,
.social_blogger_circle,
.social_spotify_circle,
.social_delicious_circle,
.social_facebook_square,
.social_twitter_square,
.social_pinterest_square,
.social_googleplus_square,
.social_tumblr_square,
.social_stumbleupon_square,
.social_wordpress_square,
.social_instagram_square,
.social_dribbble_square,
.social_vimeo_square,
.social_linkedin_square,
.social_rss_square,
.social_deviantart_square,
.social_share_square,
.social_myspace_square,
.social_skype_square,
.social_youtube_square,
.social_picassa_square,
.social_googledrive_square,
.social_flickr_square,
.social_blogger_square,
.social_spotify_square,
.social_delicious_square,
.icon_printer,
.icon_calulator,
.icon_building,
.icon_floppy,
.icon_drive,
.icon_search-2,
.icon_id,
.icon_id-2,
.icon_puzzle,
.icon_like,
.icon_dislike,
.icon_mug,
.icon_currency,
.icon_wallet,
.icon_pens,
.icon_easel,
.icon_flowchart,
.icon_datareport,
.icon_briefcase,
.icon_shield,
.icon_percent,
.icon_globe,
.icon_globe-2,
.icon_target,
.icon_hourglass,
.icon_balance,
.icon_rook,
.icon_printer-alt,
.icon_calculator_alt,
.icon_building_alt,
.icon_floppy_alt,
.icon_drive_alt,
.icon_search_alt,
.icon_id_alt,
.icon_id-2_alt,
.icon_puzzle_alt,
.icon_like_alt,
.icon_dislike_alt,
.icon_mug_alt,
.icon_currency_alt,
.icon_wallet_alt,
.icon_pens_alt,
.icon_easel_alt,
.icon_flowchart_alt,
.icon_datareport_alt,
.icon_briefcase_alt,
.icon_shield_alt,
.icon_percent_alt,
.icon_globe_alt,
.icon_clipboard {
  font-family: 'ElegantIcons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

.arrow_up:before {
  content: "\21";
}

.arrow_down:before {
  content: "\22";
}

.arrow_left:before {
  content: "\23";
}

.arrow_right:before {
  content: "\24";
}

.arrow_left-up:before {
  content: "\25";
}

.arrow_right-up:before {
  content: "\26";
}

.arrow_right-down:before {
  content: "\27";
}

.arrow_left-down:before {
  content: "\28";
}

.arrow-up-down:before {
  content: "\29";
}

.arrow_up-down_alt:before {
  content: "\2a";
}

.arrow_left-right_alt:before {
  content: "\2b";
}

.arrow_left-right:before {
  content: "\2c";
}

.arrow_expand_alt2:before {
  content: "\2d";
}

.arrow_expand_alt:before {
  content: "\2e";
}

.arrow_condense:before {
  content: "\2f";
}

.arrow_expand:before {
  content: "\30";
}

.arrow_move:before {
  content: "\31";
}

.arrow_carrot-up:before {
  content: "\32";
}

.arrow_carrot-down:before {
  content: "\33";
}

.arrow_carrot-left:before {
  content: "\34";
}

.arrow_carrot-right:before {
  content: "\35";
}

.arrow_carrot-2up:before {
  content: "\36";
}

.arrow_carrot-2down:before {
  content: "\37";
}

.arrow_carrot-2left:before {
  content: "\38";
}

.arrow_carrot-2right:before {
  content: "\39";
}

.arrow_carrot-up_alt2:before {
  content: "\3a";
}

.arrow_carrot-down_alt2:before {
  content: "\3b";
}

.arrow_carrot-left_alt2:before {
  content: "\3c";
}

.arrow_carrot-right_alt2:before {
  content: "\3d";
}

.arrow_carrot-2up_alt2:before {
  content: "\3e";
}

.arrow_carrot-2down_alt2:before {
  content: "\3f";
}

.arrow_carrot-2left_alt2:before {
  content: "\40";
}

.arrow_carrot-2right_alt2:before {
  content: "\41";
}

.arrow_triangle-up:before {
  content: "\42";
}

.arrow_triangle-down:before {
  content: "\43";
}

.arrow_triangle-left:before {
  content: "\44";
}

.arrow_triangle-right:before {
  content: "\45";
}

.arrow_triangle-up_alt2:before {
  content: "\46";
}

.arrow_triangle-down_alt2:before {
  content: "\47";
}

.arrow_triangle-left_alt2:before {
  content: "\48";
}

.arrow_triangle-right_alt2:before {
  content: "\49";
}

.arrow_back:before {
  content: "\4a";
}

.icon_minus-06:before {
  content: "\4b";
}

.icon_plus:before {
  content: "\4c";
}

.icon_close:before {
  content: "\4d";
}

.icon_check:before {
  content: "\4e";
}

.icon_minus_alt2:before {
  content: "\4f";
}

.icon_plus_alt2:before {
  content: "\50";
}

.icon_close_alt2:before {
  content: "\51";
}

.icon_check_alt2:before {
  content: "\52";
}

.icon_zoom-out_alt:before {
  content: "\53";
}

.icon_zoom-in_alt:before {
  content: "\54";
}

.icon_search:before {
  content: "\55";
}

.icon_box-empty:before {
  content: "\56";
}

.icon_box-selected:before {
  content: "\57";
}

.icon_minus-box:before {
  content: "\58";
}

.icon_plus-box:before {
  content: "\59";
}

.icon_box-checked:before {
  content: "\5a";
}

.icon_circle-empty:before {
  content: "\5b";
}

.icon_circle-slelected:before {
  content: "\5c";
}

.icon_stop_alt2:before {
  content: "\5d";
}

.icon_stop:before {
  content: "\5e";
}

.icon_pause_alt2:before {
  content: "\5f";
}

.icon_pause:before {
  content: "\60";
}

.icon_menu:before {
  content: "\61";
}

.icon_menu-square_alt2:before {
  content: "\62";
}

.icon_menu-circle_alt2:before {
  content: "\63";
}

.icon_ul:before {
  content: "\64";
}

.icon_ol:before {
  content: "\65";
}

.icon_adjust-horiz:before {
  content: "\66";
}

.icon_adjust-vert:before {
  content: "\67";
}

.icon_document_alt:before {
  content: "\68";
}

.icon_documents_alt:before {
  content: "\69";
}

.icon_pencil:before {
  content: "\6a";
}

.icon_pencil-edit_alt:before {
  content: "\6b";
}

.icon_pencil-edit:before {
  content: "\6c";
}

.icon_folder-alt:before {
  content: "\6d";
}

.icon_folder-open_alt:before {
  content: "\6e";
}

.icon_folder-add_alt:before {
  content: "\6f";
}

.icon_info_alt:before {
  content: "\70";
}

.icon_error-oct_alt:before {
  content: "\71";
}

.icon_error-circle_alt:before {
  content: "\72";
}

.icon_error-triangle_alt:before {
  content: "\73";
}

.icon_question_alt2:before {
  content: "\74";
}

.icon_question:before {
  content: "\75";
}

.icon_comment_alt:before {
  content: "\76";
}

.icon_chat_alt:before {
  content: "\77";
}

.icon_vol-mute_alt:before {
  content: "\78";
}

.icon_volume-low_alt:before {
  content: "\79";
}

.icon_volume-high_alt:before {
  content: "\7a";
}

.icon_quotations:before {
  content: "\7b";
}

.icon_quotations_alt2:before {
  content: "\7c";
}

.icon_clock_alt:before {
  content: "\7d";
}

.icon_lock_alt:before {
  content: "\7e";
}

.icon_lock-open_alt:before {
  content: "\e000";
}

.icon_key_alt:before {
  content: "\e001";
}

.icon_cloud_alt:before {
  content: "\e002";
}

.icon_cloud-upload_alt:before {
  content: "\e003";
}

.icon_cloud-download_alt:before {
  content: "\e004";
}

.icon_image:before {
  content: "\e005";
}

.icon_images:before {
  content: "\e006";
}

.icon_lightbulb_alt:before {
  content: "\e007";
}

.icon_gift_alt:before {
  content: "\e008";
}

.icon_house_alt:before {
  content: "\e009";
}

.icon_genius:before {
  content: "\e00a";
}

.icon_mobile:before {
  content: "\e00b";
}

.icon_tablet:before {
  content: "\e00c";
}

.icon_laptop:before {
  content: "\e00d";
}

.icon_desktop:before {
  content: "\e00e";
}

.icon_camera_alt:before {
  content: "\e00f";
}

.icon_mail_alt:before {
  content: "\e010";
}

.icon_cone_alt:before {
  content: "\e011";
}

.icon_ribbon_alt:before {
  content: "\e012";
}

.icon_bag_alt:before {
  content: "\e013";
}

.icon_creditcard:before {
  content: "\e014";
}

.icon_cart_alt:before {
  content: "\e015";
}

.icon_paperclip:before {
  content: "\e016";
}

.icon_tag_alt:before {
  content: "\e017";
}

.icon_tags_alt:before {
  content: "\e018";
}

.icon_trash_alt:before {
  content: "\e019";
}

.icon_cursor_alt:before {
  content: "\e01a";
}

.icon_mic_alt:before {
  content: "\e01b";
}

.icon_compass_alt:before {
  content: "\e01c";
}

.icon_pin_alt:before {
  content: "\e01d";
}

.icon_pushpin_alt:before {
  content: "\e01e";
}

.icon_map_alt:before {
  content: "\e01f";
}

.icon_drawer_alt:before {
  content: "\e020";
}

.icon_toolbox_alt:before {
  content: "\e021";
}

.icon_book_alt:before {
  content: "\e022";
}

.icon_calendar:before {
  content: "\e023";
}

.icon_film:before {
  content: "\e024";
}

.icon_table:before {
  content: "\e025";
}

.icon_contacts_alt:before {
  content: "\e026";
}

.icon_headphones:before {
  content: "\e027";
}

.icon_lifesaver:before {
  content: "\e028";
}

.icon_piechart:before {
  content: "\e029";
}

.icon_refresh:before {
  content: "\e02a";
}

.icon_link_alt:before {
  content: "\e02b";
}

.icon_link:before {
  content: "\e02c";
}

.icon_loading:before {
  content: "\e02d";
}

.icon_blocked:before {
  content: "\e02e";
}

.icon_archive_alt:before {
  content: "\e02f";
}

.icon_heart_alt:before {
  content: "\e030";
}

.icon_star_alt:before {
  content: "\e031";
}

.icon_star-half_alt:before {
  content: "\e032";
}

.icon_star:before {
  content: "\e033";
}

.icon_star-half:before {
  content: "\e034";
}

.icon_tools:before {
  content: "\e035";
}

.icon_tool:before {
  content: "\e036";
}

.icon_cog:before {
  content: "\e037";
}

.icon_cogs:before {
  content: "\e038";
}

.arrow_up_alt:before {
  content: "\e039";
}

.arrow_down_alt:before {
  content: "\e03a";
}

.arrow_left_alt:before {
  content: "\e03b";
}

.arrow_right_alt:before {
  content: "\e03c";
}

.arrow_left-up_alt:before {
  content: "\e03d";
}

.arrow_right-up_alt:before {
  content: "\e03e";
}

.arrow_right-down_alt:before {
  content: "\e03f";
}

.arrow_left-down_alt:before {
  content: "\e040";
}

.arrow_condense_alt:before {
  content: "\e041";
}

.arrow_expand_alt3:before {
  content: "\e042";
}

.arrow_carrot_up_alt:before {
  content: "\e043";
}

.arrow_carrot-down_alt:before {
  content: "\e044";
}

.arrow_carrot-left_alt:before {
  content: "\e045";
}

.arrow_carrot-right_alt:before {
  content: "\e046";
}

.arrow_carrot-2up_alt:before {
  content: "\e047";
}

.arrow_carrot-2dwnn_alt:before {
  content: "\e048";
}

.arrow_carrot-2left_alt:before {
  content: "\e049";
}

.arrow_carrot-2right_alt:before {
  content: "\e04a";
}

.arrow_triangle-up_alt:before {
  content: "\e04b";
}

.arrow_triangle-down_alt:before {
  content: "\e04c";
}

.arrow_triangle-left_alt:before {
  content: "\e04d";
}

.arrow_triangle-right_alt:before {
  content: "\e04e";
}

.icon_minus_alt:before {
  content: "\e04f";
}

.icon_plus_alt:before {
  content: "\e050";
}

.icon_close_alt:before {
  content: "\e051";
}

.icon_check_alt:before {
  content: "\e052";
}

.icon_zoom-out:before {
  content: "\e053";
}

.icon_zoom-in:before {
  content: "\e054";
}

.icon_stop_alt:before {
  content: "\e055";
}

.icon_menu-square_alt:before {
  content: "\e056";
}

.icon_menu-circle_alt:before {
  content: "\e057";
}

.icon_document:before {
  content: "\e058";
}

.icon_documents:before {
  content: "\e059";
}

.icon_pencil_alt:before {
  content: "\e05a";
}

.icon_folder:before {
  content: "\e05b";
}

.icon_folder-open:before {
  content: "\e05c";
}

.icon_folder-add:before {
  content: "\e05d";
}

.icon_folder_upload:before {
  content: "\e05e";
}

.icon_folder_download:before {
  content: "\e05f";
}

.icon_info:before {
  content: "\e060";
}

.icon_error-circle:before {
  content: "\e061";
}

.icon_error-oct:before {
  content: "\e062";
}

.icon_error-triangle:before {
  content: "\e063";
}

.icon_question_alt:before {
  content: "\e064";
}

.icon_comment:before {
  content: "\e065";
}

.icon_chat:before {
  content: "\e066";
}

.icon_vol-mute:before {
  content: "\e067";
}

.icon_volume-low:before {
  content: "\e068";
}

.icon_volume-high:before {
  content: "\e069";
}

.icon_quotations_alt:before {
  content: "\e06a";
}

.icon_clock:before {
  content: "\e06b";
}

.icon_lock:before {
  content: "\e06c";
}

.icon_lock-open:before {
  content: "\e06d";
}

.icon_key:before {
  content: "\e06e";
}

.icon_cloud:before {
  content: "\e06f";
}

.icon_cloud-upload:before {
  content: "\e070";
}

.icon_cloud-download:before {
  content: "\e071";
}

.icon_lightbulb:before {
  content: "\e072";
}

.icon_gift:before {
  content: "\e073";
}

.icon_house:before {
  content: "\e074";
}

.icon_camera:before {
  content: "\e075";
}

.icon_mail:before {
  content: "\e076";
}

.icon_cone:before {
  content: "\e077";
}

.icon_ribbon:before {
  content: "\e078";
}

.icon_bag:before {
  content: "\e079";
}

.icon_cart:before {
  content: "\e07a";
}

.icon_tag:before {
  content: "\e07b";
}

.icon_tags:before {
  content: "\e07c";
}

.icon_trash:before {
  content: "\e07d";
}

.icon_cursor:before {
  content: "\e07e";
}

.icon_mic:before {
  content: "\e07f";
}

.icon_compass:before {
  content: "\e080";
}

.icon_pin:before {
  content: "\e081";
}

.icon_pushpin:before {
  content: "\e082";
}

.icon_map:before {
  content: "\e083";
}

.icon_drawer:before {
  content: "\e084";
}

.icon_toolbox:before {
  content: "\e085";
}

.icon_book:before {
  content: "\e086";
}

.icon_contacts:before {
  content: "\e087";
}

.icon_archive:before {
  content: "\e088";
}

.icon_heart:before {
  content: "\e089";
}

.icon_profile:before {
  content: "\e08a";
}

.icon_group:before {
  content: "\e08b";
}

.icon_grid-2x2:before {
  content: "\e08c";
}

.icon_grid-3x3:before {
  content: "\e08d";
}

.icon_music:before {
  content: "\e08e";
}

.icon_pause_alt:before {
  content: "\e08f";
}

.icon_phone:before {
  content: "\e090";
}

.icon_upload:before {
  content: "\e091";
}

.icon_download:before {
  content: "\e092";
}

.social_facebook:before {
  content: "\e093";
}

.social_twitter:before {
  content: "\e094";
}

.social_pinterest:before {
  content: "\e095";
}

.social_googleplus:before {
  content: "\e096";
}

.social_tumblr:before {
  content: "\e097";
}

.social_tumbleupon:before {
  content: "\e098";
}

.social_wordpress:before {
  content: "\e099";
}

.social_instagram:before {
  content: "\e09a";
}

.social_dribbble:before {
  content: "\e09b";
}

.social_vimeo:before {
  content: "\e09c";
}

.social_linkedin:before {
  content: "\e09d";
}

.social_rss:before {
  content: "\e09e";
}

.social_deviantart:before {
  content: "\e09f";
}

.social_share:before {
  content: "\e0a0";
}

.social_myspace:before {
  content: "\e0a1";
}

.social_skype:before {
  content: "\e0a2";
}

.social_youtube:before {
  content: "\e0a3";
}

.social_picassa:before {
  content: "\e0a4";
}

.social_googledrive:before {
  content: "\e0a5";
}

.social_flickr:before {
  content: "\e0a6";
}

.social_blogger:before {
  content: "\e0a7";
}

.social_spotify:before {
  content: "\e0a8";
}

.social_delicious:before {
  content: "\e0a9";
}

.social_facebook_circle:before {
  content: "\e0aa";
}

.social_twitter_circle:before {
  content: "\e0ab";
}

.social_pinterest_circle:before {
  content: "\e0ac";
}

.social_googleplus_circle:before {
  content: "\e0ad";
}

.social_tumblr_circle:before {
  content: "\e0ae";
}

.social_stumbleupon_circle:before {
  content: "\e0af";
}

.social_wordpress_circle:before {
  content: "\e0b0";
}

.social_instagram_circle:before {
  content: "\e0b1";
}

.social_dribbble_circle:before {
  content: "\e0b2";
}

.social_vimeo_circle:before {
  content: "\e0b3";
}

.social_linkedin_circle:before {
  content: "\e0b4";
}

.social_rss_circle:before {
  content: "\e0b5";
}

.social_deviantart_circle:before {
  content: "\e0b6";
}

.social_share_circle:before {
  content: "\e0b7";
}

.social_myspace_circle:before {
  content: "\e0b8";
}

.social_skype_circle:before {
  content: "\e0b9";
}

.social_youtube_circle:before {
  content: "\e0ba";
}

.social_picassa_circle:before {
  content: "\e0bb";
}

.social_googledrive_alt2:before {
  content: "\e0bc";
}

.social_flickr_circle:before {
  content: "\e0bd";
}

.social_blogger_circle:before {
  content: "\e0be";
}

.social_spotify_circle:before {
  content: "\e0bf";
}

.social_delicious_circle:before {
  content: "\e0c0";
}

.social_facebook_square:before {
  content: "\e0c1";
}

.social_twitter_square:before {
  content: "\e0c2";
}

.social_pinterest_square:before {
  content: "\e0c3";
}

.social_googleplus_square:before {
  content: "\e0c4";
}

.social_tumblr_square:before {
  content: "\e0c5";
}

.social_stumbleupon_square:before {
  content: "\e0c6";
}

.social_wordpress_square:before {
  content: "\e0c7";
}

.social_instagram_square:before {
  content: "\e0c8";
}

.social_dribbble_square:before {
  content: "\e0c9";
}

.social_vimeo_square:before {
  content: "\e0ca";
}

.social_linkedin_square:before {
  content: "\e0cb";
}

.social_rss_square:before {
  content: "\e0cc";
}

.social_deviantart_square:before {
  content: "\e0cd";
}

.social_share_square:before {
  content: "\e0ce";
}

.social_myspace_square:before {
  content: "\e0cf";
}

.social_skype_square:before {
  content: "\e0d0";
}

.social_youtube_square:before {
  content: "\e0d1";
}

.social_picassa_square:before {
  content: "\e0d2";
}

.social_googledrive_square:before {
  content: "\e0d3";
}

.social_flickr_square:before {
  content: "\e0d4";
}

.social_blogger_square:before {
  content: "\e0d5";
}

.social_spotify_square:before {
  content: "\e0d6";
}

.social_delicious_square:before {
  content: "\e0d7";
}

.icon_printer:before {
  content: "\e103";
}

.icon_calulator:before {
  content: "\e0ee";
}

.icon_building:before {
  content: "\e0ef";
}

.icon_floppy:before {
  content: "\e0e8";
}

.icon_drive:before {
  content: "\e0ea";
}

.icon_search-2:before {
  content: "\e101";
}

.icon_id:before {
  content: "\e107";
}

.icon_id-2:before {
  content: "\e108";
}

.icon_puzzle:before {
  content: "\e102";
}

.icon_like:before {
  content: "\e106";
}

.icon_dislike:before {
  content: "\e0eb";
}

.icon_mug:before {
  content: "\e105";
}

.icon_currency:before {
  content: "\e0ed";
}

.icon_wallet:before {
  content: "\e100";
}

.icon_pens:before {
  content: "\e104";
}

.icon_easel:before {
  content: "\e0e9";
}

.icon_flowchart:before {
  content: "\e109";
}

.icon_datareport:before {
  content: "\e0ec";
}

.icon_briefcase:before {
  content: "\e0fe";
}

.icon_shield:before {
  content: "\e0f6";
}

.icon_percent:before {
  content: "\e0fb";
}

.icon_globe:before {
  content: "\e0e2";
}

.icon_globe-2:before {
  content: "\e0e3";
}

.icon_target:before {
  content: "\e0f5";
}

.icon_hourglass:before {
  content: "\e0e1";
}

.icon_balance:before {
  content: "\e0ff";
}

.icon_rook:before {
  content: "\e0f8";
}

.icon_printer-alt:before {
  content: "\e0fa";
}

.icon_calculator_alt:before {
  content: "\e0e7";
}

.icon_building_alt:before {
  content: "\e0fd";
}

.icon_floppy_alt:before {
  content: "\e0e4";
}

.icon_drive_alt:before {
  content: "\e0e5";
}

.icon_search_alt:before {
  content: "\e0f7";
}

.icon_id_alt:before {
  content: "\e0e0";
}

.icon_id-2_alt:before {
  content: "\e0fc";
}

.icon_puzzle_alt:before {
  content: "\e0f9";
}

.icon_like_alt:before {
  content: "\e0dd";
}

.icon_dislike_alt:before {
  content: "\e0f1";
}

.icon_mug_alt:before {
  content: "\e0dc";
}

.icon_currency_alt:before {
  content: "\e0f3";
}

.icon_wallet_alt:before {
  content: "\e0d8";
}

.icon_pens_alt:before {
  content: "\e0db";
}

.icon_easel_alt:before {
  content: "\e0f0";
}

.icon_flowchart_alt:before {
  content: "\e0df";
}

.icon_datareport_alt:before {
  content: "\e0f2";
}

.icon_briefcase_alt:before {
  content: "\e0f4";
}

.icon_shield_alt:before {
  content: "\e0d9";
}

.icon_percent_alt:before {
  content: "\e0da";
}

.icon_globe_alt:before {
  content: "\e0de";
}

.icon_clipboard:before {
  content: "\e0e6";
}

/*============================================================================================*/
/* 1.  SITE STRUCTURE and TYPOGRAPHY */
/*============================================================================================*/

@import 'variables';
@import 'mixin';

/*-------- 1.1 Typography --------*/
/* rem reference
10px = 0.625rem
12px = 0.75rem
14px = 0.875rem
16px = 1rem (base)
18px = 1.125rem
20px = 1.25rem
24px = 1.5rem
30px = 1.875rem
32px = 2rem
*/

@import url("https://fonts.googleapis.com/css?family=Poppins:400,300,500,600,700");
/* Font Family */

html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: "Poppins", Helvetica, Arial, sans-serif !important;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  background: $body-color;
  @include fontSize(14px);
  font-family: $font-main;
  color: $body-text-color;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $heading-color;
  font-weight: 500;
  @include transition-default;
}

.title_small {
  @include fontSize(21px);
  font-weight: 600;
}

p {
  margin-bottom: 0px !important;
}

strong {
  font-weight: 500;
}

label {
  font-weight: 500;
  margin-bottom: 0px !important;
}

hr {
  margin-top: 25px;
  margin-bottom: 25px;
  border-color: $border-color-2;
}

/*General links color*/
a {
  color: $color-1;
  text-decoration: none;
  @include transition-default;
  outline: none;

  &:hover,
  &:focus {
    color: #111;
    text-decoration: none;
    outline: none;
  }
}

/*-------- 1.2 Structure --------*/
@media (min-width: 1410px) {
  .container {
    max-width: 1320px;
  }
}

@media (max-width: 1200px) {
  .container {
    max-width: 90%;
  }
}

@media (max-width: 560px) {
  .container {
    max-width: 100%;
  }
}

.small-gutters {
  margin-right: -5px !important;
  margin-left: -5px !important;

  >[class*="col-"] {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
}

/* Header */
header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  padding: 15px 0;
  z-index: 500;
  border-bottom: 1px solid rgba(255, 255, 255, 0);

  &.header {
    @include mobile {
      padding: 10px 15px;
    }

    .logo_sticky {
      display: none;
    }

    &.sticky {
      @include transition-default;
      border-bottom: 1px solid $border-color-2;
      background-color: #fff;

      @include tablet {
        padding: 10px 15px;
      }

      .logo_normal {
        display: none;
      }

      .logo_sticky {
        display: inline-block;
      }
    }
  }

  &.header_in {
    padding: 5px 0 5px;
    background-color: #fff;
    position: relative;
    border-bottom: 1px solid $border-color-2;

    .container{
      display: flex;
      width: 100%;
      justify-content: space-between;
    }

    &.is_sticky {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
    }

    ul#top_menu li a {
      color: #444;
    }

    @include tablet {
      padding: 10px 0;

      #logo a {
        z-index: 9;
        position: relative;
      }
    }
  }
}


header.black_nav a {
  color: #222;

  &:hover {
    opacity: 1;
    color: $color-1 !important;
  }
}

header.sticky,
.header_in {
  color: #222;

  &:hover {
    opacity: 1;
    color: $color-1;
  }
}

#logo {
  float: left;

  @include tablet {
    float: none;
    text-align: left;

    img {
      width: auto;
      height: 28px;

    }
  }
}

/*-------- 1.3 Main Menu --------*/
.main-menu {
  position: relative;
  z-index: 9;
  top: 10px;
  float: right;
  @include transition-default;

  ul,
  ul li {
    position: relative;
    margin: 0;
    padding: 0;

    a {
      position: relative;
      margin: 0;
      padding: 0;
      display: block;
      padding: 10px;
    }
  }
}

/*First level styles */
.main-menu>ul>li {
  >a {
    color: #fff;
    padding: 0 8px 10px 8px;
    @include fontSize(14px);
    font-weight: 500;
  }

  &.black>ul>li>a {
    color: #444;
  }

  &:hover>a {
    color: $color-1;
  }
}

header.sticky .main-menu>ul>li,
header.header_in .main-menu>ul>li,
header.black_nav .main-menu>ul>li {
  >a {
    color: #222;
  }

  &:hover>a {
    opacity: 1;
    color: $color-1;
  }
}

#close_in,
#header_menu {
  display: none;
}


/* All styles for screen size over 992px
================================================== */
@media only screen and (min-width: 992px) {

  .main-menu {
    width: auto;
  }

  .main-menu a {
    white-space: nowrap;
  }

  .main-menu ul li {
    display: inline-block;
  }

  .main-menu ul li.submenu:hover>a:before,
  .main-menu ul li.submenu:hover>a:after {
    bottom: -10px;
    opacity: 0;
  }

  /* Submenu*/
  .main-menu ul ul {
    position: absolute;
    border-top: 2px solid $color-1;
    z-index: 1;
    visibility: hidden;
    left: 3px;
    top: 100%;
    margin: 0;
    display: block;
    padding: 0;
    background: #fff;
    min-width: 210px;
    @include box-shadow (0px 6px 12px 0px rgba(0, 0, 0, 0.175));
    transform: scale(0.4);
    transform-origin: 10% top;
    -webkit-transition: all .6s cubic-bezier(.77, 0, .175, 1);
    -moz-transition: all .6s cubic-bezier(.77, 0, .175, 1);
    transition: all .6s cubic-bezier(.77, 0, .175, 1);
    @include border-radius(3px);
    opacity: 0;
    @include transition-default (all, 0.2s, ease);

    &:before {
      bottom: 100%;
      left: 12%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-bottom-color: $color-1;
      border-width: 7px;
      margin-left: -7px;
    }
  }

  .main-menu ul li:hover>ul {
    padding: 0;
    visibility: visible;
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    -webkit-transition-duration: 0s, 0.2s, 0.2s;
    transition-duration: 0s, 0.2s, 0.2s
  }

  .main-menu ul ul li {
    display: block;
    height: auto;
    padding: 0;
  }

  .main-menu ul ul li a {
    @include fontSize(13px);
    color: $body-text-color;
    display: block;
    padding: 12px 15px;
    @include transition-default (all, 0.3s, ease);
  }

  .main-menu ul ul li:last-child a {
    border-bottom: none;
  }

  .main-menu ul ul li {
    &:first-child a {
      &:hover {
        @include border-radius(3px 3px 0 0);
      }
    }

    &:last-child a {
      border-bottom: none;

      &:hover {
        @include border-radius(0 0 3px 3px);
      }
    }
  }

  .main-menu ul ul li:hover>a {
    background-color: #f9f9f9;
    color: $color-1;
    padding-left: 18px;
  }

  /* Submenu 3rd level */
  .main-menu ul ul li.third-level>a {
    font-weight: normal !important;

    &:hover {
      background-color: #f9f9f9;
      color: $color-1;
      padding-left: 18px;
      opacity: 1;
    }

    &:after {
      font-family: 'ElegantIcons';
      content: "\35";
      float: right;
      @include fontSize(16px);
      margin-top: -2px;
    }
  }

  .main-menu ul ul ul {
    position: absolute;
    border-top: 0;
    z-index: 1;
    height: auto;
    left: 100%;
    top: 0;
    margin: 0;
    padding: 0;
    background: #fff;
    min-width: 190px;
    @include box-shadow (0px 6px 12px 0px rgba(0, 0, 0, 0.175));
  }

  /* Arrows top 3rd level*/
  .main-menu ul ul ul:before {
    border-width: 0;
    margin-left: 0;
  }

}

@media only screen and (max-width: 991px) {
  #header_menu {
    display: block;
    text-align: center;
    position: relative;
    padding: 30px 0 25px 0;
    background-color: $color-1;
  }

  .main-menu ul li a:hover,
  a.show-submenu:hover {
    color: $color-1 !important;
    background-color: #f9f9f9;
    opacity: 1 !important;
  }

  .main-menu ul li {
    border-top: none;
    border-bottom: 1px solid #ededed;
    color: #fff;
  }

  /* Menu mobile first level */
  .main-menu ul li a {
    padding: 15px !important;
  }

  .main-menu li,
  .main-menu a {
    display: block;
    color: #333 !important;
  }

  .main-menu li {
    position: relative;
  }

  .main-menu ul>li {
    padding-bottom: 0;
  }

  .main-menu ul>li i {
    float: right;
  }

  .main-menu>ul>li.submenu:hover>a {
    &:after {
      transform: rotate(180deg);
      color: $color-1;
    }
  }

  .main-menu ul>li.submenu>a:after {
    font-family: 'ElegantIcons';
    @include fontSize(21px);
    content: "\33";
    color: #444;
    line-height: 1;
    float: right;
    display: inline-block;
    @include transition-default;
  }

  /* Menu mobile second level */
  .main-menu ul li.submenu ul {
    border-left: 1px solid #ededed;
    margin: 0 0 10px 25px;
  }

  .main-menu ul li.submenu ul li {
    border: 0;
    @include fontSize(13px);
  }

  /* Menu mobile 3rd level */
  .main-menu ul li.submenu ul ul {
    border-left: none;
    margin: 0 0 0 15px;
  }

  /* Menu mobile left panel */
  .main-menu {
    overflow-y: scroll;
    transform: translateX(-100%);
    top: 0;
    left: 0;
    bottom: 0;
    width: 55%;
    height: 100%;
    position: fixed;
    z-index: 999;
    background-color: #fff;
    -webkit-transition: all .6s cubic-bezier(.77, 0, .175, 1);
    -moz-transition: all .6s cubic-bezier(.77, 0, .175, 1);
    transition: all .6s cubic-bezier(.77, 0, .175, 1);
  }

  .main-menu.show {
    transform: translateX(0);
  }

  .main-menu .show-submenu+ul {
    display: none;
    visibility: hidden;
  }

  .main-menu a.show-submenu+ul.show_normal {
    display: block;
    visibility: visible;
  }

}

@media only screen and (max-width: 480px) {
  .main-menu {
    width: 100%;
  }
}

#home-icon {
  font-size: 21px;
}

ul#top_menu {
  margin: 0;
  float: right;
  padding: 0;
  list-style: none;
  display: flex;
  align-content: center !important;
  flex-wrap: wrap;
  @include fontSize(13px);

  @include tablet {
    position: absolute;
    top: 12px;
    right: 15px;
    float: none;
  }

  li {
    float: left;
    margin: 0;
    line-height: 1;
    display: flex;
    align-content: center !important;
    flex-wrap: wrap;

    &:last-child {
      margin-right: 0;
      position: relative;
      top: 0;
    }

    mat-icon {

      &:hover {
        color: $color-8;
        opacity: 0.9;
      }

      &.login,
      &.cart_bt,
      &.wishlist_bt_top {
        cursor: pointer;
        display: block;
        width: 22px;
        height: 23px;
        top: 8px;

        @include tablet {
          top: 2px;
        }

        @include transition-default (opacity, 0.5s, ease);

        &:before {
          font-family: 'Glyphter';
          @include fontSize(21px);
          text-indent: 0;
          position: absolute;
          left: 0;
          top: 0;
          font-weight: normal;
          line-height: $line-height-none;

          .sticky & {
            color: #444;
          }
        }
      }

      strong {
        @include fontSize(10px);
        font-weight: 700;
        width: 16px;
        height: 16px;
        text-indent: 0;
        display: block;
        text-align: center;
        position: absolute;
        bottom: -5px;
        right: -4px;
        line-height: 17px !important;
        @include border-radius(50%);
        background-color: $color-8;
        color: #fff !important;

        @include mobile {
          bottom: -3px;
        }
      }

      &.wishlist_bt_top {
        text-indent: -9999px;

        &:before {
          content: '\0043';
        }
      }

      &.login {
        text-indent: -9999px;

        &:before {
          content: '\0042';
        }
      }
    }
  }
}

/*-------- 1.5 Heros/Above the fold  --------*/
.hero_single {
  width: 100%;
  position: relative;
  margin: 0;
  color: #fff;

  .opacity-mask {
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1,
    h3 {
      color: #fff;
      line-height: 1;
      @include fontSize(42px);
      margin: 0;
      font-weight: 600;

      @include mobile {
        @include fontSize(36px);
      }
    }

    p {
      @include fontSize(28px);
      font-weight: 300;

      @include mobile {
        @include fontSize(21px);
      }
    }
  }

  .search_trends {
    color: #555;

    h5 {
      @include fontSize(14px);
      display: inline-block;
      color: #555;
    }

    ul {
      list-style: none;
      display: inline-block;
      margin: 15px 0 0 0;
      padding: 0;

      li {
        display: inline-block;

        &:after {
          content: ", ";
        }

        &:last-child:after {
          content: " ";
        }

        a {
          color: #555;

          &:hover {
            text-decoration: underline;
          }
        }
      }

    }
  }

  &.version_1 {
    height: 620px;
    background: #faf3cc url(assets/images/hero_5.svg) center top no-repeat;

    @include tablet {
      background: #faf3cc url(assets/images/hero_5_mobile.svg) center top no-repeat;
    }

    .opacity-mask {
      @include tablet {
        text-align: center !important;
      }

      h1,
      h3 {
        color: #222;
      }

      p {
        color: #444;
      }
    }
  }

  &.version_2 {
    height: 620px;
    background: #faf3cc url(assets/images/home_section_2.jpg) center center no-repeat;
    @include background-size(cover);

    .opacity-mask {
      text-align: left;

      @include tablet {
        text-align: center !important;
      }

      h1,
      h3 {
        color: #fff;
      }

      p {
        color: #fff;
      }
    }

    .search_trends {
      color: #fff;

      h5 {
        color: #fff;
      }

      ul {

        li {

          a {
            color: #fff;
          }
        }

      }
    }
  }

  &.inner_pages {
    height: 620px;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #ededed;
    @include background-size(cover);
    text-align: center;

    h1 {
      margin-top: -30px;
    }
  }
}

.custom-search-input {
  background-color: #fff;
  padding: 5px 5px 5px 0;
  @include border-radius(5px);
  margin-top: 0px;
  margin-bottom: 10px;
  margin-left: 0px;
  margin-right: 0px;
  @include box-shadow (0px 0px 30px 0px rgba(0, 0, 0, 0.2));

  @include tablet {
    background: none;
    @include box-shadow (none);
  }

  input {
    border: 0;
    height: 50px;
    padding: 12px 10px 10px 20px;
    font-weight: 500;
    @include border-radius(0);
    @include fontSize(15px);

    &.no_border_r {
      border: none;
    }

    @include tablet {
      border: none;
      @include border-radius(3px);
      height: 60px;
      @include box-shadow (0px 0px 30px 0px rgba(0, 0, 0, 0.2));
    }

    &:focus {
      box-shadow: none;

      @include tablet {
        border-right: none;
        @include box-shadow (0px 0px 30px 0px rgba(0, 0, 0, 0.2));
      }
    }
  }

  select {
    display: none;
  }

  .form-group {
    margin: 0;

    @include tablet {
      margin-bottom: 5px;
    }
  }

  i {
    @include border-radius(3px);
    @include fontSize(18px);
    position: absolute;
    background-color: #fff;
    line-height: 50px;
    top: 0;
    right: 1px;
    padding-right: 15px;
    display: block;
    width: 20px;
    box-sizing: content-box;
    height: 48px;
    z-index: 9;
    color: #999;

    @include tablet {
      padding-right: 10px;
    }
  }

  button[type='submit'] {
    padding: 0 25px;
    height: 50px;
    width: 100%;
    margin-right: -1px;
    @include border-radius(3px);
    font-weight: 600;

    @include tablet {
      margin: 15px 0 0 0;
    }
  }
}

body {
  padding-right: 0 !important;
}

body.modal-open{
  width: 100%;
}

.modal::-webkit-scrollbar {
  display: none;
}

.search_trends {
  color: #555;

  h5 {
    @include fontSize(14px);
    display: inline-block;
    color: #555;
  }

  ul {
    list-style: none;
    display: inline-block;
    margin: 15px 0 0 0;
    padding: 0;

    li {
      display: inline-block;

      &:after {
        content: ", ";
      }

      &:last-child:after {
        content: " ";
      }

      a {
        color: #555;

        &:hover {
          color: $body-text-color;
          text-decoration: underline;
        }
      }
    }

  }
}

.hero_in {
  &.detail_page {
    width: 100%;
    height: 270px;
    position: relative;
    overflow: hidden;
    color: #fff;
    text-align: left;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #ededed;
    @include background-size(cover);

    @include mobile {
      height: 400px;
    }

    .container {
      position: relative;
      height: 100%;
    }

    .wrapper {
      .buttons {
        text-align: right;
        font-weight: 600;

        a {
          cursor: pointer;
        }

        @include mobile {
          margin-top: 10px;
          position: static
        }

        a.btn_hero {
          background-color: #fff;
          @include border-radius(3px);
          @include box-shadow (0px 0px 15px 0px rgba(0, 0, 0, 0.20));
          line-height: 1;
          padding: 10px 15px;
          color: #444 !important;
          font-weight: 500;
          color: #444;
          text-decoration: none !important;
          display: inline-block;

          i {
            margin-right: 8px;
          }

          &:hover {
            color: $color-1 !important;
          }

          &.liked {
            color: #FF5A5F !important;
          }
        }
      }

      .main_info {
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 999;
        padding: 0 10px 40px 10px;
        font-weight: 500;

        .head {
          margin-bottom: 10px;

          .title{
            text-align: center;
          }

          .score {
            display: inline-block;

            span {
              float: right;
              text-align: left;
              margin: -2px 0 0 8px;

              em {
                display: block;
                font-weight: normal;
                @include fontSize(11px);
              }

            }

            strong {
              background-color: #66cc66;
              @include border-radius(5px 5px 5px 0);
              @include fontSize(15px);
              padding: 8px;
              line-height: 1.1;

              &:before {
                color: #fff;
              }
            }
          }
        }

        h1 {
          color: #fff;
          margin: 0;
          text-transform: none;
          @include fontSize(36px);
          font-weight: 600;

          @include mobile {
            @include fontSize(32px);
          }
        }

        h3 {
          color: #fff;
          margin: 0;
          @include fontSize(26px);
          font-weight: 400;

          @include mobile {
            margin-top: 10px;
            @include fontSize(21px);
          }
        }

        a {
          color: #fff;
          text-decoration: underline;

        }
      }

    }
  }
}

.page_header {
  padding: 20px 0 20px 0;
  background-color: #f4f4f4;

  @include mobile {
    padding: 15px 0;
  }

  h1 {
    @include fontSize(16px);
    margin: 0;

    @include mobile {
      @include fontSize(16px);
    }
  }

  &.sticky {
    @include mobile {
      background-color: $color-1;
      width: 100%;
      position: fixed;
      left: 0;
      top: 0;
      z-index: 999999;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      -webkit-animation-name: smoothScroll;
      animation-name: smoothScroll;
      -webkit-animation-duration: 0.5s;
      animation-duration: 0.5s;
    }
  }
}

@keyframes smoothScroll {
  0% {
    transform: translateY(-200px);

  }

  100% {
    transform: translateY(0px);

  }
}

.search_bar_list {
  position: relative;

  input[type='text'] {
    border: 0;
    height: 40px;
    @include border-radius(3px);
    padding-left: 15px;
    @include box-shadow (0 0 0 0.15rem rgba(0, 0, 0, 0.05));

    &:focus {
      @include box-shadow (0 0 0 0.15rem rgba(0, 0, 0, 0.08));
    }
  }

  button[type='submit'] {
    background-color: #fff;
    position: absolute;
    color: #999;
    top: 2px;
    right: 10px;
    border: 0;
    height: 38px;
    cursor: pointer;
    @include transition-default;
    @include fontSize(18px);
    outline: none;

    &:hover {
      color: $color-1;
    }

    &:focus {
      box-shadow: none;
    }
  }
}

/*-------- 1.5 Drop down cart --------*/
.dropdown-cart .dropdown-menu,
.dropdown.user .dropdown-menu {
  margin-top: 11px;
  margin-left: -205px;
  border: none;
  min-width: 0;
  padding: 15px;
  width: 260px;
  top: 100%;
  @include border-radius(3px);
  @include box-shadow (0px 5px 10px 0px rgba(0, 0, 0, 0.175));
  @include fontSize(13px);
  border-top: 2px solid $color-1;

  &:before {
    bottom: 100%;
    right: 12%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: $color-1;
    border-width: 7px;
    margin-left: -7px;
  }

  .total_drop {
    @include fontSize(16px);

    strong {
      float: left;
    }

    span {
      float: right;
      font-weight: 600;
    }

    .btn_1 {
      margin-top: 5px;
      display: block;

      &.outline {
        color: #333 !important;
      }
    }
  }

  ul {
    padding: 0;
    list-style: none;

    li {
      margin: 0 0 5px 0 !important;
      padding: 0 15px 0 60px;
      display: block;
      min-height: 60px;
      position: relative;
      float: none !important;

      figure {
        @include border-radius(3px);
        width: 50px;
        height: 50px;
        overflow: hidden;
        position: absolute;
        margin: 0;
        left: 0;
        top: 0;

        img {
          width: auto;
          height: 50px;
          position: absolute;
          left: 50%;
          top: 50%;
          @include transform (translate(-50%, -50%) scale(1.1));
        }
      }

      a.action {
        color: #999;
        position: absolute;
        right: 0;
        top: 0;
        width: 15px;
        height: 15px;

        i {
          font-size: 16px;
          font-weight: normal;
          color: #777;

          &:hover {
            color: #1a1a1a;
          }
        }
      }

      strong {
        color: $body-text-color;
        margin-top: 5px;
        display: inline-block;
        color: #333;
        font-weight: 600;

        span {
          display: block;
          font-weight: normal;
          margin-bottom: 5px;
        }
      }
    }
  }

  a.btn_1 {
    height: auto;
    line-height: 1.4;
    opacity: 1 !important;

    &.outline {
      background-color: transparent;

      &:hover {
        opacity: 1 !important;
        color: #fff !important;
      }
    }

    &:hover {
      color: #fff !important;
      background: $color-1 !important;
    }
  }
}

.dropdown-cart .dropdown-menu {
  @include tablet {
    display: none;
  }
}

header.black_nav ul#top_menu li,
header.black_nav ul#top_menu li .dropdown-cart,
header.black_nav .dropdown.user {
  >a {
    color: #333 !important;
  }
}

/*-------- 1.6 Drop down user logged --------*/
.dropdown.user {
  padding: 0;
  border: 0;
  margin-bottom: 0;
  height: 100%;

  button {
    float: left;
    line-height: 1;
    color: #fff;
    font-weight: 500;
    @include fontSize(14px);
    display: block;
    position: relative;

    @include mobile {
      padding: 0;
    }

    .icon_person{
      display: none;
      @include mobile {
        display: inline !important;
      }
    }

    .icon_arrow {
      @include mobile {
        display: none !important;
      }
    }

    h4 {
      @include mobile {
        display: none;
      }
    }

    figure {
      width: 34px;
      height: 34px;
      overflow: hidden;
      position: absolute;
      left: 0;
      top: 2px;
      display: inline-block;
      @include border-radius(50%);
      border: 2px solid #ededed;

      img {
        position: absolute;
        left: 50%;
        top: 50%;
        @include transform (translate(-50%, -50%));
        @include backface-visibility (hidden);
        width: 100%;

      }
    }
  }

  &.show a:after {
    transform: rotate(180deg);
  }

  .dropdown-menu {
    padding: 0;
    width: auto;
    right: -15px !important;
    margin: 5px 0 0 0;
    min-width: 135px;
    width: auto;

    @include tablet {
      margin: 23px 5px 0 0;
    }
  }
}

header.black_nav .dropdown.user {
  a {
    color: #333 !important;

    &:hover {
      opacity: 1 !important;
    }
  }
}

.dropdown.user .dropdown-menu {

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      min-height: inherit;
      padding: 0;
      margin: 0 !important;

      a {
        padding: 12px 15px;
        display: block;
        @include fontSize(13px);

        i {
          margin-right: 8px;
        }

        &:hover {
          background-color: #f9f9f9;
          color: $color-1 !important;
          opacity: 1;
        }
      }
    }
  }
}

ul#top_menu.drop_user {
  @include tablet {
    top: 6px;
    right: 10px;
  }
}

.header_in {
  ul#top_menu.drop_user {
    @include tablet {
      right: 0;
    }
  }
}

/*============================================================================================*/
/* 2.  CONTENT */
/*============================================================================================*/
/*-------- 2.1 Home/front page --------*/
.main_title {
  position: relative;

  span {
    width: 120px;
    height: 2px;
    background-color: #e1e1e1;
    display: block;

    em {
      width: 60px;
      height: 2px;
      background-color: $color-1;
      display: block;
    }
  }

  margin-bottom: 25px;
  position: relative;

  @include mobile {
    margin-bottom: 10px;
  }

  h2 {
    @include fontSize(26px);
    max-width: 600px;
    margin: 20px 0 0 0;

    @include mobile {
      @include fontSize(22px);
    }
  }

  p {
    max-width: 600px;
    @include fontSize(18px);
    font-weight: 300;
  }

  a {
    position: absolute;
    right: 0;
    bottom: 0;
    font-weight: 500;

    @include tablet {
      display: none
    }

    ;
  }

  &.center {
    text-align: center;

    span {
      margin: auto;

      em {
        margin: auto;
      }
    }

    h2 {
      max-width: inherit;
      @include fontSize(34px);

      @include mobile {
        @include fontSize(28px);
      }
    }

    p {
      @include fontSize(21px);
      font-weight: 300;
      max-width: inherit;
    }
  }

  &.version_2 {
    a {
      right: 0;

      @include mobile {
        display: none;
      }
    }
  }
}

.intro_txt {
  .main_title {
    margin-bottom: 15px;

    h2 {
      @include fontSize(32px);
      font-weight: 600;
    }
  }
}

/*-------- 2.2 Carousel --------*/
/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */
  padding-left: 0px !important;
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
  height: auto;
}

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
			This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
			calculation of the height of the owl-item that breaks page layouts
		 */
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 1;
  transition: all 400ms ease;
}

.owl-carousel .owl-item .owl-lazy[src^=""],
.owl-carousel .owl-item .owl-lazy:not([src]) {
  max-height: 0;
}

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
/*
 * 	Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-nav {
  position: absolute;
  z-index: 999;
  -webkit-tap-highlight-color: transparent;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next {
  outline: none;
  background-color: #fff;
}

.owl-theme .owl-nav [class*='owl-'] {
  color: #222 !important;
  margin: 5px 0;
  padding: 4px 7px;
  display: inline-block;
  cursor: pointer;
}

.owl-theme .owl-nav [class*='owl-']:hover {
  color: #FFF;
  text-decoration: none;
}

.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}

.owl-theme .owl-nav.disabled+.owl-dots {
  margin-top: 30px;
}

.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  *display: inline;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  outline: none;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: transparent !important;
  border: 2px solid $color-1;
  @include transition-default;
}

.owl-theme .owl-dots .owl-dot span {
  position: relative;
  margin: 0 5px;
  border-radius: 50%;
  width: 16px !important;
  height: 16px !important;
  background: transparent !important;
  display: block;
  -webkit-backface-visibility: visible;
  transition: all .3s ease;
  border: 2px solid #ccc;

  &::before {
    content: '';
    background-color: $color-1 !important;
    display: block;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -4px;
    margin-left: -4px;
    opacity: 0;
    visibility: hidden;
    transform: scale(0);
    @include transition-default;
  }
}

.owl-theme .owl-dots .owl-dot.active span::before {
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}

.owl-theme {
  .owl-nav {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    margin: 0 !important;

    .owl-prev,
    .owl-next {
      width: 46px;
      height: 46px;
      position: absolute;
      top: 0;
      margin-top: -25px;
      display: block !important;
      background-color: #fff;
      @include border-radius(50%);
      border: 1px solid rgba(0, 0, 0, .04);
      @include box-shadow (0 1px 4px rgba(0, 0, 0, .08));

    }

    .owl-prev {
      left: -24px;
    }

    .owl-next {
      right: -24px;
    }

    .owl-prev i,
    .owl-next i {
      @include fontSize(28px);
      line-height: 48px !important;
      color: #999;
      @include transition-default;

      &:hover {
        color: $color-1;
      }
    }

    .owl-prev.disabled,
    .owl-next.disabled {
      opacity: 0;
    }
  }
}

/* Jquery select */
// Default variables
$font_size: 14px !default;
$font_size_small: 12px !default;

$input_border_radius: 5px !default;
$input_height: 40px !default;
$input_height_small: 36px !default;
$dropdown_padding: 15px !default;

$gray_dark: #444 !default;
$gray: #999 !default;
$gray_light: #999 !default;
$gray_lighter: #f2f2f2 !default;
$primary_light: $gray !default;
$arrow_color: $gray_light !default;

// Style the dropdown
.nice-select {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #fff;
  border-radius: $input_border_radius;
  border: none;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: inherit;
  font-size: $font_size;
  font-weight: 400;
  height: $input_height;
  line-height: calc($input_height - 2);
  outline: none;
  padding-left: $dropdown_padding;
  padding-right: $dropdown_padding + 12;
  position: relative;
  text-align: left !important;
  transition: all 0.2s ease-in-out;
  user-select: none;
  white-space: nowrap;
  width: auto;
  color: #6d757d;
  padding-top: 2px;

  &:active,
  &:focus {
    border-color: $primary_light;
  }

  // Arrow
  &:after {
    border-bottom: 2px solid $arrow_color;
    border-right: 2px solid $arrow_color;
    content: '';
    display: block;
    height: 8px;
    margin-top: -5px;
    pointer-events: none;
    position: absolute;
    right: 20px;
    top: 50%;
    transform-origin: 66% 66%;
    transform: rotate(45deg);
    transition: all 0.15s ease-in-out;
    width: 8px;
  }

  &.open {
    @extend :active;

    &:after {
      transform: rotate(-135deg);
    }

    .list {
      opacity: 1;
      pointer-events: auto;
      transform: scale(1) translateY(0);
    }
  }

  // Modifiers
  &.wide {
    width: 100%;

    .list {
      left: -1px !important;
      right: -1px !important;
    }
  }

  // List and options
  .list {
    background-color: #fff;
    border-radius: $input_border_radius;
    box-shadow: 0 0 0 1px rgba($gray_dark, .11);
    margin-top: 8px;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    pointer-events: none;
    position: absolute;
    top: 100%;
    left: 0;
    transform-origin: 50% 0;
    transform: scale(.75) translateY(-($input_height / 2));
    transition: all .2s cubic-bezier(0.5, 0, 0, 1.25), opacity .15s ease-out;
    z-index: 9999;

    &:hover .option:not(:hover) {
      background-color: transparent !important;
    }
  }

  .option {
    cursor: pointer;
    font-weight: 400;
    line-height: calc($input_height - 2);
    list-style: none;
    min-height:  calc($input_height - 10);
    outline: none;
    padding-left: $dropdown_padding;
    padding-right: $dropdown_padding + 11;
    text-align: left;
    transition: all 0.2s;

    &:hover,
    &.focus,
    &.selected.focus {
      background-color: $gray_lighter;
    }

    &.selected {
      color: $body-text-color;
      font-weight: 500;
    }

    &.disabled {
      background-color: transparent;
      color: $gray;
      cursor: default;
    }
  }
}

// Use display instead of opacity for IE <= 10
.no-csspointerevents .nice-select {
  .list {
    display: none;
  }

  &.open {
    .list {
      display: block;
    }
  }
}

/*-------- 2.3 Strip item --------*/
.strip {
  position: relative;
  margin-bottom: 30px;

  figure {
    margin-bottom: 5px;
    overflow: hidden;
    position: relative;
    height: 190px;
    @include border-radius(5px);
    background-color: #ededed;

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      @include transform (translate(-50%, -50%) scale(1));
      @include backface-visibility (hidden);
      width: 100%;
      z-index: 1;
      @include transition-default;
    }

    &:hover img {
      @include transform (translate(-50%, -50%) scale(1.1));

    }

    a.strip_info {
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      height: 100%;
      width: 100%;
      z-index: 5;
      display: block;

      >small {
        position: absolute;
        @include rgba(bg, #fff, 1);
        left: 15px;
        top: 15px;
        color: #333;
        font-weight: 500;
        @include border-radius(3px);
        padding: 6px 8px 4px 8px;
        line-height: 1;
        @include fontSize(12px);
      }
    }

    .item_title {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 65px 15px 10px 15px;
      background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 5%, rgba(0, 0, 0, 1) 100%);
      background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 5%, rgba(0, 0, 0, 1) 100%);
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 5%, rgba(0, 0, 0, 1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#000000', GradientType=0);

      h3 {
        @include fontSize(16px);
        margin: 0;
        font-weight: 500;
        color: #fff;
        margin: 0;
        padding: 0;
        line-height: 1;

      }

      small {
        font-weight: 500;
        line-height: 1;
        @include fontSize(13px);
        color: rgba(255, 255, 255, 0.8);
      }
    }
  }

  ul {
    padding: 0;
    margin: 0;

    li {
      display: inline-block;
      padding-top: 12px;
      @include fontSize(13px);

      &:last-child {
        padding: 0;
        float: right;
      }
    }
  }
}

.ribbon {
  color: #fff;
  display: inline-block;
  position: absolute;
  top: 15px;
  right: 15px;
  font-weight: 500;
  @include border-radius(3px);
  padding: 6px 8px 4px 23px;
  line-height: 1;
  @include fontSize(12px);
  z-index: 9;

  &.off {
    background-color: $color-2;

    &:before {
      font-family: 'food';
      content: '\0076';
      position: absolute;
      left: 8px;
      top: 4px;
    }
  }
}

.score {
  strong {
    padding: 10px 0 0 0;
    display: inline-block;
    @include fontSize(13px);
    font-weight: 500;

    &:before {
      font-family: 'ElegantIcons';
      content: "\e033";
      margin: -4px 3px 0 0;
      color: #66cc66;
    }
  }
}

.take,
.deliv {
  @include fontSize(12px);
  position: relative;
  padding-left: 20px;
  margin-right: 10px;
  font-weight: 500;
  color: $body-text-color;

  &:before {
    @include fontSize(16px);
    font-family: "ElegantIcons";
    content: "\e013";
    position: absolute;
    line-height: $line-height-none;
    font-weight: normal;
  }

  &.yes {}

  &.no {
    text-decoration: line-through;
    color: #bbb;
  }
}

.take {
  &:before {
    content: "\e013";
    left: 0;
    top: -1px;
  }

  &.yes {}

  &.no {
    text-decoration: line-through;
    color: #bbb;
  }
}

.deliv {
  padding-left: 30px;
  margin-right: 0;

  &:before {
    @include fontSize(24px);
    font-family: 'food';
    content: '\0074';
    left: 0;
    top: -4px;
  }
}

/*============================================================================================*/
/* 3. COMMON */
/*============================================================================================*/
/*-------- 3.1 Misc --------*/
.box_info_1 {
  margin-bottom: 20px;

  h3 {
    @include fontSize(21px);
  }

  .wrapper_img {
    position: relative;

    span {
      display: inline-block;
      width: 100%;
      height: 100%;
      @include border-radius(3px);
      border: 2px solid $border-color-2;
      position: absolute;
      left: 15px;
      top: 15px;
      z-index: 1;
    }

    figure {
      margin: 0 0 35px 0;
      -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.11);
      -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.11);
      box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.11);
      @include border-radius(3px);
      width: auto;
      height: 220px;
      overflow: hidden;
      position: relative;
      z-index: 2;

    }
  }
}

.wave {
  width: 100%;
  background-attachment: fixed;
  position: absolute;
  width: 100%;
  /*height from the svg file*/
  height: 65px;
  top: -65px;
  left: 0;
  background: url("assets/images/wave_white.svg");
  z-index: 99;
  animation: waves 20s linear infinite;

  &:before,
  &:after {
    content: '';
    width: 100%;
    height: 65px;
    background: url("assets/images/wave_white.svg");
    position: absolute;
    top: 0;
    left: 0;
  }

  &:before {
    opacity: 0.4;
    animation: waves-reverse 20s linear infinite;
  }

  &:after {
    opacity: 0.6;
    animation-delay: -5s;
    animation: waves 30s linear infinite;
  }

  &.hero {
    top: auto;
    bottom: 0;
  }

  &.footer {
    transform: scale(-1, -1);
    top: 0;

    @include mobile {
      top: -1px;
    }
  }

  &.gray {
    background: url("assets/images/wave_gray.svg");

    &:before,
    &:after {
      background: url("assets/images/wave_gray.svg");
      position: absolute;
    }
  }
}

@keyframes waves {
  0% {
    background-position: 0;
  }

  100% {
    /* from width of the svg file */
    background-position: 1600px;
  }
}

@keyframes waves-reverse {
  0% {
    background-position: 1600px;
  }

  100% {
    background-position: 0;
  }
}

.promo {
  background: $color-1 url(/frontend/CardapioEletronico/src/assets/images/pattern.svg) center center repeat;
  @include border-radius(5px);
  margin-bottom: 25px;
  padding: 20px 25px;
  color: #fff;
  position: relative;

  h3 {
    color: #fff;
    margin-bottom: 0;
    @include fontSize(24px);

    @include mobile {
      @include fontSize(20px);
    }
  }

  p {
    margin: 0;
  }

  i {
    position: absolute;
    right: 30px;
    bottom: 0;
    @include fontSize(62px);
    height: 52px;

    @include mobile {
      display: none;
    }
  }

  &.mb_30 {
    margin-bottom: 30px;
  }
}

.bg_gray {
  background-color: #f9fafb;

  &.pattern {
    background: #f4f4f4 url(assets/images/pattern_2.svg) repeat;
  }

  &.waves_divider {
    position: relative;
    padding-bottom: 25px;
    margin-bottom: 25px;

    &:before {
      content: '';
      background: url(assets/images/wave_gray.svg) repeat;
      width: 100%;
      height: 65px;
      @include background-size(cover);
      position: absolute;
      left: 0;
      top: -65px;
    }

    &:after {
      transform: scale(-1, -1);
      content: '';
      background: url(assets/images/wave_gray.svg) repeat;
      width: 100%;
      height: 65px;
      @include background-size(cover);
      position: absolute;
      left: 0;
      top: auto;
      bottom: -65px;
    }
  }
}

/*-------- 3.2 Spacing --------*/
.margin_60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.margin_30_40 {
  padding-top: 30px;
  padding-bottom: 40px;
}

.margin_30_20 {
  padding-top: 30px;
  padding-bottom: 20px;
}

.margin_30_60 {
  padding-top: 30px;
  padding-bottom: 60px;
}

.margin_60_40 {
  padding-top: 60px;
  padding-bottom: 40px;
}

.margin_60_30 {
  padding-top: 60px;
  padding-bottom: 30px;
}

.margin_60_20 {
  padding-top: 60px;
  padding-bottom: 20px;
}

.margin_60_0 {
  padding-top: 60px;
  padding-bottom: 0;
}

.margin_detail {
  padding-top: 25px;
  padding-bottom: 60px;

  @include tablet {
    padding-top: 5px;
    padding-bottom: 30px;
  }
}

aside {
  color: black;
}

.margin_detail_2 {
  padding-top: 35px;
  padding-bottom: 30px;
}

.add_bottom_15 {
  padding-bottom: 15px;
}

.add_bottom_25 {
  padding-bottom: 25px;
}

.add_bottom_30 {
  margin-bottom: 30px;
}

.add_bottom_45 {
  padding-bottom: 45px;
}

.add_top_30 {
  margin-top: 30px;
}

.mb_5 {
  margin-bottom: 5px
}

.margin_mobile {
  @include mobile {
    padding-top: 30px;
    padding-bottom: 10px;
  }
}

/*-------- 3.3 Buttons --------*/
a.btn_1,
.btn_1 {
  border: none;
  background-color: $color-1;
  outline: none;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  padding: 8px 25px;
  color: #fff;
  font-weight: 500;
  text-align: center;
  @include fontSize(14px);
  @include transition-default;
  @include border-radius(3px);
  line-height: normal;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: $color-2;
  }

  &.full-width {
    display: block;
    width: 100%;
    text-align: center;
  }

  &.small {
    @include fontSize(12px);
    padding: 7px 15px;
  }

  &.medium {
    @include fontSize(15px);
    padding: 12px 30px;
  }

  &.outline {
    border: 2px solid #111;
    color: #111;
    padding: 6px 15px;
    background: none;

    &:hover {
      border-color: $color-1;
      color: $color-1 !important;
    }

    &:focus {
      outline: none;
    }
  }

  &.gray {
    background: #f0f0f0;
    color: $color-1;

    &:hover {
      background-color: $color-1;
      color: #fff !important;
    }
  }

  &.full-width {
    display: block;
    width: 100%;
    text-align: center;
  }

  &.plus_icon {
    padding-bottom: 5px;
    padding-right: 10px;

    i {
      @include fontSize(26px);
      float: right;
      line-height: 1;
    }

    &.medium {
      padding-bottom: 10px;
      padding-right: 20px;
    }
  }

  &.gradient:enabled {
    background-size: 100%;
    background-image: -webkit-linear-gradient(right, $color-2, $color-1);
    background-image: linear-gradient(to right, $color-2, $color-1);
    position: relative;
    z-index: 1;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:before:enabled {
      border-radius: inherit;
      background-image: -webkit-linear-gradient(right, $color-1, $color-1);
      background-image: linear-gradient(to right, $color-1, $color-1);
      content: '';
      display: block;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: -1;
      -webkit-transition: opacity 0.45s ease-in-out;
      transition: opacity 0.45s ease-in-out;
    }

    &:hover:before {
      opacity: 1;
    }

    &:active:before {
      opacity: 1;
    }
  }
}

.pulse_bt {
  box-shadow: 0 0 0 rgba($color-1, 0.4);
  animation: pulseanime 3s infinite;
  -webkit-animation: pulseanime 3s infinite;
}

@-webkit-keyframes pulseanime {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba($color-1, 0.4);
  }

  70% {
    -webkit-box-shadow: 0 0 0 10px rgba($color-1, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba($color-1, 0);
  }
}

@keyframes pulseanime {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba($color-1, 0.4);
    box-shadow: 0 0 0 0 rgba($color-1, 0.4);
  }

  70% {
    -moz-box-shadow: 0 0 0 10px rgba($color-1, 0);
    box-shadow: 0 0 0 10px rgba($color-1, 0);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba($color-1, 0);
    box-shadow: 0 0 0 0 rgba($color-1, 0);
  }
}

/*-------- 3.4 Footer --------*/
@media (min-width: 768px) {
  .collapse.dont-collapse-sm {
    display: block !important;
    height: auto !important;
    visibility: visible !important;
  }
}



/* Newsletter footer*/
@-webkit-keyframes rotation {
  from {
    @include transform (rotate(0deg));
  }

  to {
    @include transform (rotate(359deg));
  }
}

#newsletter {
  @include mobile {
    margin-top: 20px;
  }

  h6 {
    margin: 15px 0 15px 0;
  }

  input[type='email'] {
    border: 0;
    height: 40px;
    @include border-radius(3px 0 0 3px);
    padding-left: 15px;
    @include rgba(bg, #fff, 0.8);
    color: $body-text-color;

    &:focus {
      border: 0;
      @include box-shadow (none);
    }
  }

  button[type='submit'] {
    position: absolute;
    right: 0;
    color: #fff;
    @include fontSize(24px);
    top: 0;
    border: 0;
    padding: 0 10px;
    height: 40px;
    line-height: 44px;
    cursor: pointer;
    @include border-radius(0 3px 3px 0);
    background-color: $color-1;
    @include transition-default;
    outline: none;

    &:hover {
      -webkit-filter: brightness(115%);
      filter: brightness(115%);
    }
  }
}

#message-newsletter #success_page {
  color: #333;
}

.follow_us {
  h5 {
    @include fontSize(14px);
    text-transform: uppercase;
    margin: 35px 0 10px 0;
    text-transform: uppercase;
  }

  ul {
    margin: 0 0 25px 0;
    padding: 0;
    list-style: none;

    li {
      display: inline-block;
      margin: 0 10px 0 0;

      a {
        display: block;

        img {
          width: 30px;
          height: 30px;
        }

        &:hover {
          @include transform (translateY(-5px));
        }
      }
    }
  }
}

ul.footer-selector {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    float: left;
    margin-right: 10px;

    &:last-child {
      @include extra-small-mobile {
        padding-top: 10px;
        clear: both;
        float: none;
        display: block !important;
      }
    }
  }
}

/* Addtional links Footer */
ul.additional_links {
  margin: 0;
  padding: 8px 0 0 0;
  color: $body-text-color;
  float: right;
  @include fontSize(13px);

  @include tablet {
    float: none;
    margin-top: 10px;
  }

  li {
    display: inline-block;
    margin-right: 15px;

    &:first-child {
      margin-right: 20px;
    }

    &:last-child {
      margin-right: 0;
    }

    &:last-child:after {
      content: "";
    }

    span {
      color: $body-text-color;
    }

    a {
      color: $body-text-color;
      @include transition-default;

      &:hover {
        color: $heading-color;
      }

    }

    &:after {
      content: "|";
      font-weight: 300;
      position: relative;
      left: 10px;
    }
  }
}

.styled-select {
  width: 100%;
  overflow: hidden;
  height: 35px;
  line-height: 32px;
  border: none;
  @include rgba(bg, #fff, 0);
  border: 1px solid #999;
  @include border-radius(3px);
  padding-right: 35px;
  position: relative;
  color: $body-text-color;
  cursor: pointer;

  &:after {
    font-family: 'ElegantIcons';
    color: $heading-color;
    position: absolute;
    right: 10px;
    top: 0;
  }

  &.lang-selector {
    &:after {
      content: "\e0e3";
    }
  }

  &.currency-selector {
    &:after {
      content: "\33";
    }
  }

  select {
    background: transparent;
    width: 110%;
    padding-left: 10px;
    border: 0;
    @include box-shadow (none);
    height: 25px;
    color: $body-text-color;
    @include fontSize(12px);
    margin: 0;
    font-weight: 500;
    -moz-appearance: window;
    -webkit-appearance: none;
    cursor: pointer;
    outline: none;

    &:focus {
      color: $body-text-color;
      outline: none;
      @include box-shadow (none);
    }

    &::-ms-expand {
      display: none;
    }
  }
}

/*-------- 3.5 Misc 2 + Forms --------*/
/* Opacity mask ove images ex.banner in home */
.opacity-mask {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}

/* Opacity mask when left open */
.layer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.35);
  @include transition-default (all, 0.1s, ease);
  opacity: 0;
  visibility: hidden;
}

.layer-is-visible {
  opacity: 1;
  visibility: visible;
}

.rotate-x {
  animation-duration: .5s;
  animation-name: rotate-x;
}

@keyframes rotate-x {
  from {
    transform: rotateY(0deg);
  }

  to {
    transform: rotateY(360deg);
  }
}

/* Forms styles */
.container_check {
  display: block;
  position: relative;
  padding-left: 30px;
  line-height: 1.7;
  margin-bottom: 8px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 400;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked~.checkmark {
      background-color: $color-1;
      border: 2px solid transparent;
      @include box-shadow (0px 0px 0px 2px rgba($color-1, 0.3));
    }
  }

  .checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 20px;
    width: 20px;
    border: 2px solid $border-color-3;
    background-color: #fff;
    @include border-radius(3px);
    @include transition-default;

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 6px;
      top: 2px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  &:hover .checkmark {
    border: 2px solid $color-1;
  }
}

.container_check input:checked~.checkmark:after {
  display: block;
}

.container_radio {
  display: block;
  position: relative;
  padding-left: 30px;
  line-height: 1.6;
  margin-bottom: 8px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 400;

  input {
    position: absolute;
    opacity: 0;

    &:checked~.checkmark {
      border: 2px solid $color-1;
      @include box-shadow (0px 0px 0px 2px rgba($color-1, 0.3));
    }

    &:checked~.checkmark:after {
      opacity: 1;
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 2px solid $border-color-3;
    background-color: #fff;
    border-radius: 50%;
    @include transition-default;

    &:after {
      display: block;
      content: "";
      position: absolute;
      opacity: 0;
      top: 3px;
      left: 3px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: $color-1;
    }
  }

  &:hover .checkmark {
    border: 2px solid $color-1;
  }
}

.form-control {
  padding: 10px;
  height: 40px;
  @include fontSize(14px);
  @include border-radius(3px);
  border: 1px solid $border-color-3;

  &:focus {
    border-color: #ccc;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.05);
  }
}

.form-group {
  position: relative;
}

.error_message {
  font-weight: 500;
  color: #e3464c;
}

/* Forms loader */
.loader {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #fff;
  @include fontSize(18px);
  -webkit-animation: rotation 2s infinite linear;
  animation: rotation 2s infinite linear;

  &.newsletter {
    right: -22px;
  }

  &.register {
    color: #444;
  }
}

/* Pagination */
.pagination_fg {
  text-align: center;
  margin-top: 15px;

  a {
    color: #333;
    display: inline-block;
    padding: 8px 16px;
    text-decoration: none;
    transition: background-color .3s;
    @include border-radius(3px);
    margin: 0 2px;

    &:hover {
      background-color: #f0f0f0;
    }

    &.active {
      background-color: #333;
      color: white;
    }
  }
}

/* Scroll top button */
#toTop {
  position: fixed;
  right: 0;
  opacity: 0;
  visibility: hidden;
  bottom: 20px;
  margin: 0 20px 0 0;
  z-index: 999;
  transition: 0.35s;
  transform: scale(0.7);
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, .6);
  opacity: 1;
  transition: all 0.3s;
  border-radius: 50%;
  text-align: center;
  @include fontSize(36px);
  color: #fff;
  cursor: pointer;

  &.detail_page {
    @include tablet {
      bottom: 70px;
      margin: 0 15px 0 0;
    }
  }

  &.visible {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }

  &:after {
    font-family: 'ElegantIcons';
    content: "\32";
    position: relative;
    display: block;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  &:hover {
    background-color: $color-8;
  }
}

/* Show hide password */


/*-------- 3.6 Magnific Popup CSS --------*/
/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  overflow: hidden;
  position: fixed;
  background: #000;
  opacity: 0.96;
}

.mfp-wrap {
  top: -10px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  height: 100%;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 18px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
}

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 505;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #aaa;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 504;
}

.mfp-preloader a {
  color: #cccccc;
}

.mfp-preloader a:hover {
  color: #fff;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

.mfp-s-loading .mfp-arrow {
  display: none;
}

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  border: 0;
  -webkit-appearance: none;
  display: block;
  padding: 0;
  z-index: 506;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  right: 0px;
  top: -40px;
}

/* Close Button */
button.mfp-close {
  position: absolute;
  width: 45px;
  height: 45px;
  top: -20px;
  display: block;
  right: -12px;
  cursor: pointer !important;
  z-index: 9999;
  color: #fff;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
  margin: 0px;
  background-color: transparent;
  outline: none;
  transform: translate3d(0, 0, 0);
}

.mfp-gallery .mfp-content .mfp-close {
  display: none;
}


.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #aaa;
  font-size: 13px;
  line-height: 18px;
}

.mfp-arrow {
  opacity: 1;
  margin: 0 20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9999;
  color: #fff;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  width: 50px;
  height: 50px;
  position: absolute;
  display: block;
  z-index: 100;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 50%;
  outline: none !important;
}

.mfp-arrow:hover {
  background: #66676b;
  color: #fff;
}

.mfp-arrow.mfp-arrow-left,
.mfp-arrow.mfp-arrow-right {
  font-family: 'ElegantIcons';
  font-size: 38px;
  line-height: 1;
}

.mfp-arrow.mfp-arrow-right {
  right: 15px;
}

.mfp-arrow.mfp-arrow-left:before,
.mfp-arrow.mfp-arrow-right:before {
  content: "\34";
  left: -1px;
  top: -1px;
  position: relative;
}

.mfp-arrow.mfp-arrow-right:before {
  content: "\35";
}

.mfp-content:hover .mfp-arrow {
  opacity: 1;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 1180px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 54%;
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: black;
}

.my-mfp-zoom-in .zoom-anim-dialog {
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
}

/* animate in */
.my-mfp-zoom-in.mfp-ready .zoom-anim-dialog {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

/* animate out */
.my-mfp-zoom-in.mfp-removing .zoom-anim-dialog {
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0;
}

/* Dark overlay, start state */
.my-mfp-zoom-in.mfp-bg {
  opacity: 0.001;
  /* Chrome opacity transition bug */
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}

/* animate in */
.my-mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.8;
}

/* animate out */
.my-mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 40px auto;
  border-radius: 4px;
}

/* The shadow behind the image */
.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 18px rgba(11, 11, 11, 0.6);
}

.mfp-figure {
  line-height: 0;
}

.mfp-bottom-bar {
  position: absolute;
  top: 102%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #f3f3f3;
  word-break: break-word;
  padding-right: 36px;
}

.mfp-figure small {
  color: #bdbdbd;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

.mfp-zoom-in {
  /* start state */
  /* animate in */
  /* animate out */
}

.mfp-zoom-in .mfp-with-anim {
  opacity: 0;
  transition: all 0.2s ease-in-out;
  transform: scale(0.8);
}

.mfp-zoom-in.mfp-bg {
  opacity: 0;
  transition: all 0.3s ease-out;
}

.mfp-zoom-in.mfp-ready .mfp-with-anim {
  opacity: 1;
  transform: scale(1);
}

.mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.9;
}

.mfp-zoom-in.mfp-removing .mfp-with-anim {
  transform: scale(0.8);
  opacity: 0;
}

.mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}

/* Media Queries for Magnific Popup */
@media screen and (max-width: 800px) and (orientation: landscape),
screen and (max-height: 300px) {
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }

  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }

  /* The shadow behind the image */
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }

  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }

  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }

  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }

  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
}

.mfp-fade.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.92;
  background-color: #111;
}

.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

/*-------- 3.7 Sing In Modal --------*/
#sign-in-dialog {
  background: #fff;
  padding: 30px;
  padding-top: 0;
  text-align: left;
  position: relative;
  box-sizing: border-box;
  @include border-radius(4px);
}

/* Popup close button*/
#sign-in-dialog .mfp-close,
#modal-dialog .mfp-close {
  color: #fff;
  @include border-radius(50%);
  top: 4px;
  right: 20px;
  width: 32px;
  height: 32px;
  line-height: 32px;

  &:before {
    font-size: 24px;
    font-family: 'ElegantIcons';
    content: "\4d";
  }

  &:hover {
    color: #fff;
    background-color: #66676b;
  }
}

#sign-in-dialog .mfp-back,
#modal-dialog .mfp-back {
  outline: none;
  top: 18px;
  position: absolute;
  display: block;
  z-index: 1;
  border: unset;
  background: unset;

  &:before {
    font-size: 24px;
    font-family: 'ElegantIcons';
    content: "4";
  }
}

.modal_body {
  padding: 20px;
}

.modal_header {
  min-height: 50px;
  max-height: 70px;
  font-size: 18px;
  position: relative;
  top: 0;
  width: 100%;
  display: inline-block;
  background-color: #f2f2f2;
  padding: 12px 20px 15px 20px;
  border-radius: 4px 4px 0 0;

  h3 {
    padding: 0;
    margin: 0;
    @include fontSize(18px);
    @include mobile {
      font-size: 0.9rem;
    }
    font-weight: 500;
    text-align: center;
  }
}



.checkboxes label,
a#forgot {
  color: #999;
  @include fontSize(13px);
}

#forgot_pw {
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 99;
  min-height: 430px;
  display: none;

  label {
    font-weight: 500;
  }
}

.sign-in-wrapper {
  position: relative;
  height: 100%;
}

a.social_bt {
  @include border-radius(3px);
  text-align: center;
  color: #fff;
  min-width: 200px;
  margin-bottom: 15px;
  display: block;
  padding: 12px;
  line-height: 1;
  position: relative;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    -webkit-filter: brightness(110%);
    filter: brightness(110%);
  }
}

a.social_bt.facebook {
  background: #3B5998 url(assets/images/Facebook__logo.svg) 10px center no-repeat;
}

a.social_bt.google {
  background: #444 url(assets/images/Google__G__Logo.svg) 10px center no-repeat;
}

.divider {
  text-align: center;
  height: 1px;
  margin: 20px 0 20px 0;
  background-color: $border-color-2;

  span {
    position: relative;
    top: -20px;
    background-color: #fff;
    display: inline-block;
    padding: 10px;
    font-style: italic;
  }
}

/* Magnific popup */
.mfp-bottom-bar {
  padding: 0 10px !important;
}

.mfp-counter {
  right: 10px !important;
  color: #fff !important;
}

.mfp-bg {
  opacity: 0.8 !important;
}

/*-------- 3.8 Modal Dialog --------*/
#modal-dialog {
  background: #fff;
  padding: 0;
  // max-width: 500px;
  @include border-radius(4px);

  .modal_header{
    background-color: #454545;

    h3 {
      color: #fff;
      text-align: center;
    }

    .button-close{
      border: none;
      position: absolute;
      right: 1rem;
      top: 0.5rem;
    }

    button {
      background: none;
    
      mat-icon{
        color: #fff;
      }
    }

    mat-icon{
      color: #fff;
    }
  }

  .small-dialog-header {
    font-size: 18px;
    width: 100%;
    position: relative;
    left: 0;
    top: 0;
    @include border-radius(4px 4px);
    display: inline-block;
    background-color: #454545;
    padding: 18px 20px 15px 20px;

    h3 {
      padding: 0;
      margin: 0;
      @include fontSize(18px);
      font-weight: 500;
    }

    h4{
      color: #fff;
    }
  }

  .content {
    padding: 20px;
  }
}

a.box_topic,
.box_topic {
  text-align: center;
  background: #fff;
  padding: 40px 30px 15px 30px;

  @include mobile {
    padding: 20px 20px 0 20px;
  }

  display: block;
  @include transition-default;
  @include box-shadow (0px 0px 15px 0px rgba(0, 0, 0, 0.05));
  color: $body-text-color;
  margin-bottom: 30px;
  overflow: hidden;
  @include border-radius(5px);

  &:hover {
    @include transform (translateY(-10px));
  }

  i {
    @include fontSize(60px);
    @include background-gradient($color-2, $color-1, 'vertical');
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: block;
    margin: 0 auto 15px;
  }

  h3 {
    @include fontSize(18px);
  }

  &.submit {
    &:hover {
      @include transform (translateY(0));
    }

    h3 {
      color: $color-1;
    }
  }
}

/*-------- 3.9 Cookie bar --------*/
#cookie-bar {
  background: rgba(0, 0, 0, 0.8);
  height: auto;
  line-height: 24px;
  color: #eeeeee;
  text-align: center;
  padding: 15px 0;
  font-weight: 400;
  @include fontSize(13px);
  font-weight: 500;

  p {
    margin: 0;
    padding: 0;
  }

  a {
    color: #fff;
    display: inline-block;
    @include border-radius(3px);
    text-decoration: none;
    padding: 2px 10px;
    margin-left: 8px;

    @include mobile {
      display: block;
      max-width: 150px;
      margin: auto;
      margin-bottom: 5px;
    }
  }

  .cb-enable {
    background: $color-3;

    &:hover {
      background: #fff;
      color: $color-2;
    }
  }

  .cb-disable {
    background: #990000;

    &:hover {
      background: #bb0000;
    }
  }

  .cb-policy {
    background: $color-1;

    &:hover {
      background: #fff;
      color: $color-2;
    }
  }

  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999999;

    &.bottom {
      bottom: 0;
      top: auto;
    }
  }

  @include mobile {
    padding: 5px 0;
  }
}

/*-------- 3.9 Accordion --------*/
.accordion_2 {
  .card {
    border: 0;
    margin-bottom: 5px;
    @include box-shadow (0px 0px 15px 0px rgba(0, 0, 0, 0.05));
    @include border-radius(5px);

    .card-header {
      background-color: #fff;
      border-bottom: 1px solid $border-color-2;
      padding: 20px;
    }
  }

  .card-body {
    padding: 20px 20px 0 20px;
  }

  .card-header h5 {
    @include fontSize(16px);
    margin: 0;

    a {
      display: block;
      color: #999;

      i.indicator {
        @include fontSize(18px);
        font-weight: normal;
        float: right;
        color: $color-1;
      }
    }
  }
}

.card {
  border-radius: 0;
  border: 0;
  margin-bottom: 5px;
}

button[disabled]:hover {
  cursor: default;
}

input[type=number] {
  padding-right: 5px
}

@media (max-width: 1000px) {
  #entrar-text {
    visibility: hidden;
  }
}

// body.modal-open {
//   height: 100vh;
//   overflow-y: hidden;
//   position: relative;
// }

//this is a fix for google autocomplete not showing up
// Ajuste para mostrar popup de seleção de endereço
.pac-container {  
  z-index: 10000 !important;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #949090;
}

.vertical-align-center {
  align-content: center;
  display: flex;
  flex-wrap: wrap;
}

.material-icons-outlined {
  font-size: 1.8rem;
  height: 30px !important;
  width: 30px !important;
  color: #444444eb;
}

.mat-mdc-menu-panel{
  max-width: 300px !important;
  overflow-x: hidden !important;
}

.mat-mdc-menu-content{
  padding: 0 !important;
}

.material-icons-outlined {
  font-size: 1.8rem !important;
}

.form-group {
  margin-bottom: 1rem;
}

.modal-content {
  border: none;
}

.head {
  text-align: center;
}

.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  color: $color-2 !important;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: var(--secondary-color) !important;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: var(--secondary-color);
  --mat-tab-header-disabled-ripple-color: var(--secondary-color);
  --mat-tab-header-pagination-icon-color: var(--secondary-color);
  --mat-tab-header-active-label-text-color: var(--secondary-color);
  --mat-tab-header-active-ripple-color: var(--secondary-color);
  --mat-tab-header-inactive-ripple-color: var(--secondary-color);
  --mat-tab-header-inactive-focus-label-text-color: var(--secondary-color);
  --mat-tab-header-inactive-hover-label-text-color: var(--secondary-color);
  --mat-tab-header-active-focus-label-text-color: var(--secondary-color);
  --mat-tab-header-active-hover-label-text-color: var(--secondary-color);
  --mat-tab-header-active-focus-indicator-color: var(--secondary-color);
  --mat-tab-header-active-hover-indicator-color: var(--secondary-color);
}

html .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: var(--secondary-color);
  --mdc-switch-selected-handle-color: var(--secondary-color);
  --mdc-switch-selected-hover-state-layer-color: var(--secondary-color);
  --mdc-switch-selected-pressed-state-layer-color: var(--secondary-color);
  --mdc-switch-selected-focus-handle-color: var(--secondary-color-dark); 
  --mdc-switch-selected-hover-handle-color: var(--secondary-color-dark);
  --mdc-switch-selected-pressed-handle-color: var(--secondary-color-dark);
  --mdc-switch-selected-focus-track-color: var(--secondary-color-light);
  --mdc-switch-selected-hover-track-color: var(--secondary-color-light);
  --mdc-switch-selected-pressed-track-color: var(--secondary-color-light);
  --mdc-switch-selected-track-color: var(--secondary-color-light);
}