//-------------------- Base Colors --------------------//

:root {
  --primary-color: #e54750;
  --primary-color-light: ;
  --primary-color-dark: ;
  --secondary-color: #f3723b;
  --secondary-color-light: ;
  --secondary-color-dark: ;
}

$body-color: #fff;
$body-text-color: #444;
$heading-color: #222;

$color-1: var(--primary-color);
$color-2: var(--secondary-color);
$color-3: #b3bac4;
$color-4: #121921;
$color-5: #FFC107;
$color-6: #66cc66;
$color-7: var(--primary-color-light);
$color-8: var(--primary-color-dark);
$color-9: var(--secondary-color-light);
$color-10: var(--secondary-color-dark);

$block-reveal-color-1: $color-2;

$border-color-2: #ededed;
$border-color-3: #d2d8dd;
$border-color-4: #ccc;
$border-color-5: #c8c8c8;

//-------------------- Line Height Variables --------------------//
$line-height-base: 1.5;
$line-height-large: 1.8;
$line-height-none: 1;

//-------------------- Font Families --------------------//
$font-main: 'Poppins',
Helvetica,
sans-serif;

//-------------------- Font Sizes --------------------//
$font-size-base: 0.875rem;

//-------------------- Responsive sizes -------------------- //
$large-device-width: 1199px; // Large devices (desktops, less than 1200px)
$medium-device-width: 991px; // Medium devices (tablets, less than 992px)
$small-device-width: 767px; // Small devices (landscape phones, less than 768px)
$extra-small-device-width: 575px; // Extra small devices (portrait phones, less than 576px)
