@use '@angular/material' as mat;

// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
@include mat.core();

@import url("https://fonts.googleapis.com/css?family=Poppins:400,300,500,600,700");

$custom-typography: mat.define-typography-config(
  $font-family: "Poppins" !important,
  $headline-5: mat.define-typography-level(36px, 32px, 500),
  $headline-6: mat.define-typography-level(20px, 32px, 500),
  $subtitle-1: mat.define-typography-level(16px, 28px, 500),
  $subtitle-2: mat.define-typography-level(20px, 24px, 500),
);

$my-theme: mat.define-light-theme((
  typography: $custom-typography
));

@include mat.all-component-themes($my-theme);

